var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "passengerMonitorRecord" },
    [
      _vm._m(0),
      _c(
        "div",
        { ref: "form", staticClass: "form-area" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: {
                inline: true,
                model: _vm.form,
                "label-position": "left",
                "label-width": "85px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "code-time-range",
                  attrs: { label: "发车时间：" },
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "daterange",
                      format: "yyyy-MM-dd",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                    },
                    model: {
                      value: _vm.departureTime,
                      callback: function ($$v) {
                        _vm.departureTime = $$v
                      },
                      expression: "departureTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "线路名称：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择线路" },
                      model: {
                        value: _vm.form.lineId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "lineId", $$v)
                        },
                        expression: "form.lineId",
                      },
                    },
                    _vm._l(_vm.lineList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "车牌号：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        remote: "",
                        "reserve-keyword": "",
                        clearable: "",
                        "remote-method": _vm.queryCph,
                        placeholder: "请输入车牌号",
                      },
                      model: {
                        value: _vm.form.vehicleId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "vehicleId", $$v)
                        },
                        expression: "form.vehicleId",
                      },
                    },
                    _vm._l(_vm.cphList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: {
                          label: item.licensePlateNumber,
                          value: item.id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "司机姓名：" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入司机姓名" },
                    model: {
                      value: _vm.form.driverName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "driverName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.driverName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "监测结果：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择监测结果" },
                      model: {
                        value: _vm.form.monitorStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "monitorStatus", $$v)
                        },
                        expression: "form.monitorStatus",
                      },
                    },
                    _vm._l(_vm.resultList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.queryFun },
                    },
                    [_vm._v("查询")]
                  ),
                  _vm.listFind("异常车辆")
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.clickVehicle },
                        },
                        [_vm._v("异常车辆")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("Table", {
        attrs: {
          "table-data": _vm.tableData,
          "title-name": _vm.titleName,
          operation: true,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "div",
                  [
                    _vm.listFind("查看")
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              size: "small",
                              sort: "primary",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.referFun(scope.scopeRow)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "pagination" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total,sizes,prev, pager, next,jumper",
              total: _vm.total,
              "page-size": _vm.form.pageSize,
              "current-page": _vm.form.currentPage,
            },
            on: {
              "size-change": _vm.onSizeChange,
              "current-change": _vm.onCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm.show
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "异常车辆",
                visible: _vm.show,
                "close-on-click-modal": false,
                width: "550px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.show = $event
                },
              },
            },
            [
              _c("p", { staticClass: "title" }, [
                _vm._v(
                  " 异常车辆：已配置抓拍摄像头且符合抓拍条件、行程已结束，可能未按规定线路行驶导致抓拍失败的车辆 "
                ),
              ]),
              _c(
                "div",
                _vm._l(_vm.unusualVehicle, function (item) {
                  return _c(
                    "el-tag",
                    {
                      key: item,
                      staticStyle: { margin: "10px" },
                      attrs: { type: "info" },
                    },
                    [_vm._v(_vm._s(item))]
                  )
                }),
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "default", size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.show = false
                        },
                      },
                    },
                    [_vm._v("关闭")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "explain" }, [
      _c("header", [
        _c("i", { staticClass: "el-icon-warning" }),
        _vm._v(" 数据说明"),
      ]),
      _c("ol", [
        _c("li", [_vm._v("仅监测行程开始前购票数小于票位数的车次")]),
        _c("li", [_vm._v("乘客数差值=实际乘客数-购票乘客数")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }