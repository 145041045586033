<template>
  <div class="passengerMonitorRecord">
    <div class="explain">
      <header><i class="el-icon-warning"></i> 数据说明</header>
      <ol>
        <li>仅监测行程开始前购票数小于票位数的车次</li>
        <li>乘客数差值=实际乘客数-购票乘客数</li>
      </ol>
    </div>
    <div ref="form" class="form-area">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="left"
        label-width="85px"
      >
        <el-form-item label="发车时间：" class="code-time-range">
          <el-date-picker
            v-model="departureTime"
            type="daterange"
            format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="线路名称：">
          <el-select v-model="form.lineId" clearable placeholder="请选择线路">
            <el-option
              v-for="item in lineList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="车牌号：">
          <el-select
            v-model="form.vehicleId"
            filterable
            remote
            reserve-keyword
            clearable
            :remote-method="queryCph"
            placeholder="请输入车牌号"
          >
            <el-option
              v-for="(item, index) in cphList"
              :key="index"
              :label="item.licensePlateNumber"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="司机姓名：">
          <el-input
            v-model.trim="form.driverName"
            clearable
            placeholder="请输入司机姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="监测结果：">
          <el-select
            v-model="form.monitorStatus"
            clearable
            placeholder="请选择监测结果"
          >
            <el-option
              v-for="item in resultList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="queryFun"
            >查询</el-button
          >
          <el-button
            v-if="listFind('异常车辆')"
            type="primary"
            size="small"
            @click="clickVehicle"
            >异常车辆</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <Table :table-data="tableData" :title-name="titleName" :operation="true">
      <template slot-scope="scope">
        <div>
          <el-button
            v-if="listFind('查看')"
            type="text"
            size="small"
            sort="primary"
            @click="referFun(scope.scopeRow)"
            >查看</el-button
          >
        </div>
      </template>
    </Table>
    <div class="pagination">
      <el-pagination
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      v-if="show"
      title="异常车辆"
      :visible.sync="show"
      :close-on-click-modal="false"
      width="550px"
    >
      <p class="title">
        异常车辆：已配置抓拍摄像头且符合抓拍条件、行程已结束，可能未按规定线路行驶导致抓拍失败的车辆
      </p>
      <div>
        <el-tag
          v-for="item in unusualVehicle"
          :key="item"
          type="info"
          style="margin: 10px"
          >{{ item }}</el-tag
        >
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="show = false"
          >关闭</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getAllLine,
  getVehicleListAllAPI,
  queryPassengerDetectionRecordPage,
  getAbnormalVehicle,
} from "@/api/lib/api.js";
import moment from "moment";
export default {
  data() {
    return {
      form: {
        currentPage: 1,
        pageSize: 10,
        startDepartureDate: null,
        vehicleId: null,
        endDepartureDate: null,
        lineId: null,
        driverName: null,
        monitorStatus: 1,
      },
      departureTime: [new Date(), new Date()],
      lineList: [],
      cphList: [],
      resultList: [
        {
          id: 1,
          name: "实际乘客数大于购票乘客数",
        },
        {
          id: 2,
          name: "实际乘客数等于购票乘客数",
        },
        {
          id: 3,
          name: "实际乘客数小于购票乘客数",
        },
      ],
      copilotList: [
        {
          id: 1,
          name: "默认有乘客",
        },
        {
          id: 2,
          name: "默认无乘客",
        },
      ],
      tableData: [],
      titleName: [
        {
          title: "发车日期",
          props: "departureDate",
          SpecialJudgment: (res) => res.slice(0, 10),
        },
        {
          title: "发车时间",
          props: "departureTime",
        },
        {
          title: "乘客数差值",
          SpecialJudgment: (res) =>
            res.ticketPassengerCount - res.actualityPassengerCount,
        },
        {
          title: "实际乘客数",
          props: "actualityPassengerCount",
        },
        {
          title: "购票乘客数",
          props: "ticketPassengerCount",
        },
        {
          title: "票位数",
          props: "ticketNum",
        },
        {
          title: "线路名称",
          props: "lineName",
        },
        {
          title: "车属公司",
          props: "companyName",
        },
        {
          title: "司机姓名",
          props: "driverName",
        },
        {
          title: "车牌号",
          props: "licensePlateNumber",
        },
      ],
      total: 0,
      show: false,
    };
  },
  methods: {
    queryFun() {
      if (this.departureTime) {
        this.form.startDepartureDate =
          moment(new Date(this.departureTime[0])).format("YYYY-MM-DD") +
          " 00:00:00";
        this.form.endDepartureDate =
          moment(new Date(this.departureTime[1])).format("YYYY-MM-DD") +
          " 23:59:59";
      } else {
        this.form.startDepartureDate = null;
        this.form.endDepartureDate = null;
      }
      this.form.currentPage = 1;
      this.renderData();
    },
    clickVehicle() {
      getAbnormalVehicle().then((res) => {
        if (res.code == "SUCCESS") {
          this.show = true;
          this.unusualVehicle = res.data;
        }
      });
    },
    queryLineList() {
      getAllLine().then((res) => {
        if (res.code == "SUCCESS") {
          this.lineList = res.data;
        }
      });
    },
    queryCph(query) {
      this.cphList = [];
      if (query !== "") {
        setTimeout(() => {
          getVehicleListAllAPI({ cph: query }).then((res) => {
            if (res.code === "SUCCESS") {
              this.cphList = res.data.filter((i) => {
                return (
                  i.licensePlateNumber.indexOf(query) > -1 &&
                  i.operationMode.length == 1 &&
                  i.operationMode[0] == 1
                );
              });
            }
          });
        }, 200);
      } else {
        this.cphList = [];
      }
    },
    // 分页页数发生变化
    onCurrentChange(val) {
      this.form.currentPage = val;
      this.renderData();
    },
    // 分页change方法
    onSizeChange(val) {
      this.form.pageSize = val;
      this.form.currentPage = 1;
      this.renderData();
    },
    renderData() {
      this.exportForm = this.deepClone(this.form);
      queryPassengerDetectionRecordPage(this.form).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    referFun(row) {
      this.$router.push({
        path: "/projectTravel/passengerMonitorInfo",
        query: { id: row.id },
      });
    },
  },
  mounted() {
    this.form.startDepartureDate =
      moment(new Date(this.departureTime[0])).format("YYYY-MM-DD") +
      " 00:00:00";
    this.form.endDepartureDate =
      moment(new Date(this.departureTime[1])).format("YYYY-MM-DD") +
      " 23:59:59";
    this.queryLineList();
    this.renderData();
  },
};
</script>
<style lang="scss" scoped>
.passengerMonitorRecord {
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  border-radius: 4px;
  padding: 16px;
  box-sizing: border-box;
  .explain {
    color: #666666;
    font-size: 14px;
    background-color: #e4ecff;
    border-radius: 6px;
    padding: 16px;
    box-sizing: border-box;
    margin-bottom: 16px;
    header {
      font-weight: bold;
      .el-icon-warning {
        color: #336ffe;
        font-size: 17px;
      }
    }
    ol {
      margin-bottom: 0;
      li {
        margin-bottom: 8px;
      }
    }
  }
  .Table {
    margin-top: 16px;
  }
  .code-time-range {
    .el-date-editor {
      width: 13vw !important;
      ::v-deep .el-range-separator {
        line-height: 24px;
      }
    }
  }
  .title {
    padding: 10px;
    color: #aaaaaa;
    line-height: 20px;
  }
}
</style>
